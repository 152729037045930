// Debounce function to prevent multiple rapid fires
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const trackCustomEvent = debounce((eventName, eventData = null) => {
  if (window.fbq && window.fbqInitialized) {
    if (eventData) {
      window.fbq('track', eventName, eventData);
    } else {
      window.fbq('track', eventName);
    }
  }
}, 500); 