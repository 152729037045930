import { useMediaQuery } from '@mui/material';
import { FaTelegram } from 'react-icons/fa';
import './App.css';
import styled, { createGlobalStyle } from 'styled-components';
import { trackCustomEvent } from './utils/pixel';

const GlobalStyle = createGlobalStyle`
  @keyframes blink-button {
    0% { 
      transform: scale(1);
      opacity: 1;
    }
    25% { 
      transform: scale(1.1);
      opacity: 0.7;
    }
    50% { 
      transform: scale(1);
      opacity: 0.5;
    }
    75% { 
      transform: scale(1.1);
      opacity: 0.7;
    }
    100% { 
      transform: scale(1);
      opacity: 1;
    }
  }
`;

function App() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:960px)');

  const handleStoreClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleJoinClick = (e) => {
    trackCustomEvent('JoinButtonClick');
    // rest of your code
  };

  return (
    <>
      <GlobalStyle />
      <div 
        style={{
          minHeight: '100vh',
          backgroundImage: `url(${process.env.PUBLIC_URL + '/bgimage.jpeg'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isMobile ? 'flex-end' : 'flex-start',
          alignItems: isMobile ? 'center' : 'flex-start',
          color: 'white',
          padding: isMobile ? '10px' : '20px'
        }}
      >
        <div 
          style={{
            backgroundColor: '#242424',
            padding: '20px',
            borderRadius: '20px',
            maxWidth: '400px',
            margin: '0 auto',
            textAlign: 'center',
            color: 'white',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            border: '1px solid rgba(255, 255, 255, 0.1)'
          }}
        >
          {/* Profile Image */}
          <div style={{
            width: '120px',
            height: '120px',
            margin: '0 auto 15px',
            borderRadius: '50%',
            overflow: 'hidden'
          }}>
            <img 
              src={process.env.PUBLIC_URL + '/tariqafridiimage.jpg'}
              alt="Trader Tariq Afridi"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </div>

          {/* Channel Name */}
          <h1 style={{
            fontSize: '23px',
            fontWeight: '500',
            margin: '0 0 5px'
          }}>
            Trader Tariq Afridi
          </h1>

          {/* Subscriber Count */}
          <div style={{ textAlign: 'center' }}>
            <p style={{ 
              color: '#888', 
              marginBottom: '20px',
              fontSize: isMobile ? '0.9rem' : '1rem'
            }}>
              44963 Subscribers
            </p>
          </div>

          {/* Description */}
          <p style={{
            fontSize: '15px',
            lineHeight: '1.5',
            margin: '0 0 20px',
            color: '#E1E1E1'
          }}>
            100% Wining signals, Sure shot, Compounding and future signals Sessions<br/>
            Binary, forex and Crypto trading expert 04+ Years Experience
          </p>

          {/* JOIN NOW Button */}
          <a
            href="https://t.me/Tradertariqafridipublic"
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '10px',
              backgroundColor: '#31A6E7',
              color: 'white',
              padding: '12px 30px',
              borderRadius: '8px',
              textDecoration: 'none',
              fontSize: '16px',
              fontWeight: '500',
              marginBottom: '20px',
              animation: 'blink-button 1.5s linear infinite'
            }}
            onClick={handleJoinClick}
          >
            <FaTelegram size={20} style={{ color: 'white' }} />
            JOIN NOW!
          </a>

          {/* Add this style block for the blinking animation */}
          <style>
            {`
              @keyframes blink-button {
                0% { 
                  opacity: 1;
                  transform: scale(1);
                }
                50% { 
                  opacity: 0.7;
                  transform: scale(1.05);
                }
                100% { 
                  opacity: 1;
                  transform: scale(1);
                }
              }
            `}
          </style>

        

          <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{ 
              color: '#888', 
              marginBottom: '20px',
              fontSize: isMobile ? '0.9rem' : '1rem',
              textAlign: 'center',
              width: '100%'
            }}>
              *connect vpn or add proxy to access telegram! *
            </p>
            <p style={{ 
              color: '#888', 
              marginBottom: '20px',
              fontSize: isMobile ? '0.9rem' : '1rem'
            }}>
              Don't have Telegram on your cell phone?
            </p>
            
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              gap: isMobile ? '10px' : '20px',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center'
            }}>
              <div 
                onClick={() => handleStoreClick('https://apps.apple.com/app/telegram/id686449807')}
                style={{ 
                  width: isMobile ? '140px' : '170px', 
                  height: '30px', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
              >
                <img 
                  src={process.env.PUBLIC_URL + '/appstoref.png'} 
                  alt="Download on App Store"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </div>
              <div 
                onClick={() => handleStoreClick('https://play.google.com/store/apps/details?id=org.telegram.messenger')}
                style={{ 
                  width: isMobile ? '130px' : '160px', 
                  height: '25px', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
              >
                <img 
                  src={process.env.PUBLIC_URL + '/playstoref.png'} 
                  alt="Get it on Google Play"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
       
        
      
        
        <div style={{
          color: '#888',
          maxWidth: isMobile ? '90%' : '800px',
          textAlign: 'center',
          fontSize: isMobile ? '0.8rem' : '0.9rem',
          marginTop: '20px',
          marginLeft: isMobile ? '0' : '100px',
          marginBottom: isMobile ? '20px' : '0'
        }}>
          <p style={{ marginBottom: '10px' }}>
            © 2024 Tariq Afridi. All Rights Reserved
          </p>
          <p style={{ marginBottom: '10px' }}>
            ATTENTION: Our products are sold exclusively through the website.
          </p>
          <p style={{ marginBottom: '20px', lineHeight: '1.5' }}>
            PLEASE NOTE: This site is not affiliated with Facebook or any Facebook entity...
          </p>
        </div>
      </div>
    </>
  );
}

export default App;
